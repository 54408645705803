import axios from "@/plugins/http.service.js";

export default {
  submit(name, email, companySize, phoneNumber, discuss) {
    var data = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      discuss: discuss,
      useCase: "SchoolBus"
    };

    return axios.post("admin/send-email", data);
  },
};
