import axios from "@/plugins/http.service.js";

export default {
  gettenants() {
    return axios.get(`tenant/get`);
  },

  add(name, created_date) {
    var data = {
      name: name,
      created_date,
    };
    return axios.post("tenant/add", data);
  },

  save(id, name) {
    var data = {
      id: id,
      name: name,
    };
    return axios.post("tenant/save", data);
  },

  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("tenant/archive", data);
  },

  add_setting(data) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    return axios.post("tenant-settings/add", { tenant_id, ...data });
  },

  save_setting(data) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    return axios.post("tenant-settings/save", { tenant_id, ...data });
  },

  get_setting(id) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    return axios.get(
      `tenant-settings/get-settings?tenant_id=${id ? id : tenant_id}`
    );
  },
};
