import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard/index.vue'
import Login from '../views/login/index.vue'
import Map from '../views/map/index.vue'
import Drivers from '../views/drivers/index.vue'
import Fines from '../views/fines/index.vue'
import Admins from '../views/admins/index.vue'
import SuperAdmin from '../views/superadmin/index.vue'
import TenantAdmins from '../views/superadmin/tenant/admin/index.vue'

import DieselTanks from '../views/diesel-tanks/index.vue'
import Accidents from '../views/accidents/index.vue'
import WareHouse from '../views/warehouse/index.vue'
import TripTypes from '../views/triptypes/index.vue'
import Trips from '../views/trips/index.vue'
import Destinations from '../views/destination/index.vue'

import PushuNotification from '../views/pushnotification/index.vue'
import Content from '../views/content/index.vue'
import Buses from '../views/bus/index.vue'
import ChangeBusRequests from '../views/changeBusRequests/index.vue'
import DriversSummary from '../views/driversSummary/index.vue'
import FinanceSummary from '../views/financeSummary/index.vue'

import Logs from '../views/logs/index.vue'
import Analytics from '../views/analytics/index.vue'
import Home from '../views/home/index.vue'
import DriverFile from '../views/driver-files'
import DriverTrips from '../views/driverTrips/index.vue'
import AllFinanceSummary from '../views/allDriversSummary/index.vue'
import oneDriverFinanceSummary from '../views/oneDriverFinanceSummary/index.vue'

import SchoolBus from '../views/usecases/schoolBus/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '*',
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') != null)
            next('/dashboard')
        else
            next('/')
    }
},
{
    path: '/schoolBus',
    name: 'SchoolBus',
    component: SchoolBus,
    meta: { lay: 'homelayout' }


},
{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { lay: 'defaultlayout' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') != null)
            next('/dashboard')
        else
            next()
    }
},
{
    path: '/logs',
    name: 'Logs',
    component: Logs,
    meta: { mainicon: 'source', title: 'Logs' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: { mainicon: 'insert_chart', title: 'Analytics' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/',
    name: 'Home',
    component: Home,
    meta: { lay: 'homelayout' },

},
{
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/map',
    name: 'Map',
    component: Map,
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/drivers',
    name: 'Drivers',
    component: Drivers,
    meta: { mainicon: 'person_search', title: 'Drivers' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
}, {
    path: '/driver-file/:id&:name',
    name: 'DriverFile',
    component: DriverFile,
    meta: { mainicon: 'description', title: '' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/one-Driver-Finance-Summary/:id&:name',
    name: 'OneDriverFinance',
    component: oneDriverFinanceSummary,
    meta: { mainicon: 'description', title: '' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/fines',
    name: 'Fines',
    component: Fines,
    meta: { mainicon: 'request_quote', title: 'Fines' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/push-notifications',
    name: 'PushNotification',
    component: PushuNotification,
    meta: { mainicon: 'notifications_none', title: 'Push-Notifications' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/admins',
    name: 'Admins',
    component: Admins,
    meta: { mainicon: 'admin_panel_settings', title: 'Admins' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/super-admins',
    name: 'Super Admins',
    component: SuperAdmin,
    meta: { mainicon: 'admin_panel_settings', title: 'Super Admins' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null || sessionStorage.getItem('is_super_admin') == 0)
            next('/')
        else
            next()
    }
},
{
    path: '/tenant/:id/admins',
    name: 'Tenant Admins',
    component: TenantAdmins,
    meta: { mainicon: 'admin_panel_settings', title: 'Tenant Admins' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/vehicles',
    name: 'Vehicles',
    component: Buses,
    meta: { mainicon: 'directions_bus', title: 'Vehicles' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/change-vehicle-requests',
    name: 'ChangeVehicleRequests',
    component: ChangeBusRequests,
    meta: { mainicon: 'sync_alt', title: 'Change Vehicle Requests' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/refuel',
    name: 'DieselTanks',
    component: DieselTanks,
    meta: { mainicon: 'local_gas_station', title: 'Refuel' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
}, {
    path: '/accidents',
    name: 'Accidents',
    component: Accidents,
    meta: { mainicon: 'bus_alert', title: 'Accidents' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/trips',
    name: 'Trips',
    component: Trips,
    meta: { mainicon: 'loyalty', title: 'Trips' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/destinations',
    name: 'Destinations',
    component: Destinations,
    meta: { mainicon: 'loyalty', title: 'Destinations' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/origins',
    name: 'Origins',
    component: WareHouse,
    meta: { mainicon: 'apartment', title: 'Origins' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }

},
{
    path: '/trip-types',
    name: 'TripTypes',
    component: TripTypes,
    meta: { mainicon: 'format_list_bulleted', title: 'Trip Types' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }

},
{
    path: '/contents',
    name: 'Content',
    component: Content,
    meta: { mainicon: 'subject', title: 'Contents' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/driver-summary',
    name: 'driver-summary',
    component: DriversSummary,
    meta: { mainicon: 'description', title: 'Drivers Summary' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/finance-summary',
    name: 'finance-summary',
    component: FinanceSummary,
    meta: { mainicon: 'description', title: 'Finance Summary' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/all-finance-summary',
    name: 'All-finance-summary',
    component: AllFinanceSummary,
    meta: { mainicon: 'description', title: 'All Drivers Finance Summary' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
{
    path: '/driver-summary/driver-trips',
    name: 'driver-summary-trips',
    component: DriverTrips,
    meta: { mainicon: 'loyalty', title: 'Drivers Trips' },
    beforeEnter: function (to, from, next) {
        if (sessionStorage.getItem('cooriroo_admin_id') == null)
            next('/')
        else
            next()
    }
},
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
