<!-- MainLayout -->
<template>
  <div id="parentx">
    <v-app id="inspire">
      <v-app-bar
        clipped-left
        elevation="0"
        app
        :style="barStyle"
      >
        <v-app-bar-nav-icon
          class="red my-5"
          app
          v-if="mobileView"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-list class="pa-0 mr-10">
          <v-list-item class="transparent">
            <v-list-item-action v-if="loggedin" style="margin: 0">
              <router-link class="" to="/">
                <div :class="{ mainlogocontainerCustom: tenant_type === 'namat', mainlogocontainer: tenant_type !== 'namat' }">
                  <img
                    style="
                      border-style: none;
                      height: 100%;
                      vertical-align: middle;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    alt="Vue logo"
                    class="logo"
                    :src="logo"
                  />
                </div>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-toolbar-title style="margin-left: -40px">
          {{tenantName}}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn plain color="#000" text v-on="on">
                <div
                  v-if="!mobileView"
                  right
                  ml-5
                  class="defont decol bold lower"
                >
                  {{ email }}
                </div>
                <v-icon small>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="onhover"
                v-for="(item, index) in dropdown_User"
                :key="index"
                :to="item.to"
              >
                <v-list-item-title
                  v-if="!mobileView"
                  @click="logout"
                  class="menucolorred alleft"
                  color="#6A6A6A"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-title
                  v-else
                  @click="logout"
                  class="menucolorred alleft"
                  color="#6A6A6A"
                  >{{ item.title + " from " + email }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        ref="asdasdl"
        v-model="drawer"
        clipped
        :right="toright"
        :mini-variant="mini"
        app
        :mini-variant-width="minWidth"
      >
        <v-list class="pt-0">
          <div :key="index" v-for="(item, index) in items">
            <v-list-item
              class="onhover"
              exact-active-class="selectedrouter"
              v-if="!item.children"
              :key="item.title"
              :to="item.to"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title class="alleft black--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>

            <v-list-group
              exact-active-class="selectedrouter"
              v-if="item.children"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator class="grouponhover">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                class="onhover"
                exact-active-class="selectedrouter"
                v-for="child in item.children"
                :key="child.title"
                :to="child.to"
              >
                <v-list-item-action>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <v-container>
            <v-layout justify-center>
              <v-btn class="nohover" color="" text @click.stop="mini = !mini">
                <div v-if="mini">
                  <img
                    class="titleimg"
                    :style="firststyle"
                    alt="Vue logo"
                    src="../assets/images/expandi.svg"
                  />
                </div>
                <div v-else>
                  <img
                    :style="secondstyle"
                    class="titleimg"
                    alt="Vue logo"
                    src="../assets/images/expandi.svg"
                  />
                </div>
              </v-btn>
            </v-layout>
          </v-container>
        </v-list>
      </v-navigation-drawer>
      <v-main app>
        <router-view :key="$route.fullPath"></router-view>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import axios from "@/plugins/http.service.js"

export default {
  name: "mainlayout",
  data() {
    return {
      email: sessionStorage.getItem("cooriroo_admin_email"),
      tenant_name: sessionStorage.getItem("tenant_name") ?? null,
      tenant_type: sessionStorage.getItem("tenant_type") ?? null,
      dropdown_User: [{ title: "Logout", to: "/" }],
      drawer: true,
      mini: true,
      toright: false,
      mobileView: false,
      fullname: "Admin",
      loggedin: false,
      items: [],
      firststyle: "color:red",
      secondstyle: "transform: scaleX(-1);filter: FlipH; ;",
      minWidth: 80,
    };
  },
  mounted() {
    this.onResize(null);
    this.loggedin = true;

    this.items = [
      {
        title: "Dashboard",
        icon: "dashboard",
        to: "/dashboard",
      },
      {
        title: "Vehicles",
        icon: "directions_bus",
        to: "/vehicles",
      },
      {
        title: this.tenant_type === "namat" ? "Staff": "Drivers",
        icon: "person_search",
        to: "/drivers",
      },
      {
        title: "Origins",
        icon: "apartment",
        to: "/origins",
      },
      {
        title: "Destinations",
        icon: "place",
        to: "/destinations",
      },
      {
        title: this.tenant_type === "namat" ? "Tasks" : "Trips",
        icon: "loyalty",
        to: "/trips",
      },
      {
        title: this.tenant_type === "namat" ? "Task types" : "Trip Types",
        icon: "format_list_bulleted",
        to: "/trip-types",
      },
      {
        title: "Refuel",
        icon: "local_gas_station",
        to: "/refuel",
      },
      {
        title: "Accidents",
        icon: "bus_alert",
        to: "/accidents",
      },
      {
        title: "Fines",
        icon: "request_quote",
        to: "/fines",
      },
      {
        title: "Reports",
        icon: "insert_chart",
        children: [
          {
            title: "Map",
            icon: "map",
            to: "/map",
          },
          {
            title: this.tenant_type === "namat" ? "Staff summary" : "Driver Summary",
            icon: "description",
            to: "/driver-summary",
          },
          {
            title: "Finance Summary",
            icon: "euro",
            to: "/finance-summary",
          },
          {
            title: "Logs",
            icon: "source",
            to: "/logs",
          },
        ],
      },
      {
        title: "Change Vehicle Requests",
        icon: "sync_alt",
        to: "/change-vehicle-requests",
      },
      {
        title: "Contents",
        icon: "subject",
        to: "/contents",
      },
      {
        title: "Admins",
        icon: "admin_panel_settings",
        to: "/admins",
      },

      {
        title: "Push Notifications",
        icon: "notifications_none",
        to: "/push-notifications",
      },
    ];
    if (this.tenant_type === "namat") {
      this.items = [
      {
        title: "Dashboard",
        icon: "dashboard",
        to: "/dashboard",
      },
      {
        title: "Messages",
        icon: "message",
        to: "/push-notifications",
      },
      {
        title: "Resources",
        icon: "now_widgets",
        to: "/vehicles",
      },
      {
        title: this.tenant_type === "namat" ? "Staff": "Drivers",
        icon: "person_search",
        to: "/drivers",
      },
      {
        title: "Locations",
        icon: "apartment",
        to: "/origins",
      },
      {
        title: this.tenant_type === "namat" ? "Tasks" : "Trips",
        icon: "check_circle_outline",
        to: "/trips",
      },
      {
        title: this.tenant_type === "namat" ? "Task types" : "Trip Types",
        icon: "format_list_bulleted",
        to: "/trip-types",
      },
      {
        title: "Emergency events",
        icon: "alarm",
        to: "/accidents",
      },
      {
        title: "Reports",
        icon: "insert_chart",
        children: [
          {
            title: this.tenant_type === "namat" ? "Performance summary" : "Driver Summary",
            icon: "description",
            to: "/driver-summary",
          },
          {
            title: "Logs",
            icon: "source",
            to: "/logs",
          },    
          {
            title: "Analytics",
            icon: "insert_chart",
            to: "/analytics",
          },
        ],
      },
      {
        title: "Change Vehicle Requests",
        icon: "sync_alt",
        to: "/change-vehicle-requests",
      },
      {
        title: "Contents",
        icon: "subject",
        to: "/contents",
      },
      {
        title: "Admins",
        icon: "admin_panel_settings",
        to: "/admins",
      },
    ];
    }

    if (sessionStorage.getItem("is_super_admin") == 1) {
      this.items.push({
        title: "Super Admins",
        icon: "admin_panel_settings",
        to: "/super-admins",
      });
    }
  },
  created() {
    document.addEventListener("resize", this.onResize);
  },
  destroyed() {
    document.removeEventListener("resize", this.onResize);
  },

  computed: {
    tenantName: function () {
      if (this.tenant_type === "namat") {
        return "";
      }
      return this.tenant_name ? "COORIROO ADMINISTRATION - " + this.tenant_name : "";
    },
    logo: function () {
      var images = require.context('../assets/images', false)
      if (this.tenant_type === "namat") {
        return images('./namat.png');
      }
      return images('./logo.svg')
    },
    barStyle: function () {
      if (this.tenant_type === "namat") {
        return "border-bottom: 2px solid #ec4962; background: white";
      }
      return "border-bottom: 2px solid #fbc853; background: white";
    }
  },

  methods: {
    logout: function() {
        axios.post("admin/logout").then(() => {
          sessionStorage.removeItem("cooriroo_admin_id");
          sessionStorage.removeItem("cooriroo_tenant_id");
          sessionStorage.removeItem("cooriroo_admin_email");
          sessionStorage.removeItem("is_super_admin");
          sessionStorage.removeItem("tenant_name");
          sessionStorage.removeItem("tenant_type");
        });
    },

    onResize() {
      this.minWidth = "80";
      if (window.innerWidth >= 1024) {
        this.mobileView = false;
      } else {
        this.mobileView = true;
      }
    },
  },
};
</script>
<style>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 16px !important;
}
</style>
