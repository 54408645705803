import api from "./api.js";
import Directions from "./direction.js";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale
);

export default {
  components: { LineChart: Line },
  data: () => ({
    lineChartData: {
      labels: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
      ],
      datasets: [
        {
          label: "Jobs Accomplished",
          data: [40, 20, 12, 22, 33, 24, 54, 11, 65, 33, 22, 22],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Jobs Pending",
          data: [32, 11, 33, 22, 22, 33, 44, 23, 35, 32, 55],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
    },
    chartOptions: {
      responsive: true,
    },
    lineChartOptions: {
      responsive: true,
      scales: {
        x: {
          type: "category",
        },
        y: {
          type: "linear",
        },
      },
    },
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    zoom: (sessionStorage.getItem("tenant_type") ?? null) === "namat" ? 9 : 11,
    center:
      (sessionStorage.getItem("tenant_type") ?? null) === "namat"
        ? { lat: 25.3548, lng: 51.1838 }
        : { lat: 25.0657, lng: 55.17128 },
    window_open: false,
    info_marker: null,
    infowindow: { lat: 10, lng: 10.0 },
    trip_window: false,
    origin_window: false,
    selected_driver: null,
    driverName: null,
    info: null,
    order: 0,
    selectedMarker: null,
    directions: null,
    driver_id: -1,
    drivers: [],
    filter: null,
    items: [],
    filters: [
      { text: "Late", value: 3 },
      { text: "Early", value: 4 },
      { text: "Finished", value: 2 },
      { text: "Waiting", value: 5 },
    ],
    markers: [],
    trip_markers: [],
    origin_markers: [],
    valid: false,
    dialog: false,
    selectedObject: null,
    loading: true,
    driverInfo: false,
    search: "",
    snackbar: false,
    text: "",
    isrequired: true,
    noRules: [],
    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    myCurrentData: [],
    tdata: [],
    adata: [],
    prompt: "", // Data binding for the textarea
    generatedialog: false, // Control for dialog visibility
    today: null,
    theaders: [
      {
        text:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "Staff"
            : "Driver Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: "Status",
        value: "flag",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
      {
        text: "Trips",
        value: "filtered_trips",
        align: "center",
      },
    ].filter(
      (th) =>
        !(
          th.text === "Trips" &&
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
        )
    ),
    routes: [],
    filtered_trips: [],
    title: "",

    // this is for calendar and namat only
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: ["Repair", "Transfer", "Check"],
  }),

  async created() {
    this.directions = new Directions(this.$refs); // Create the Direction instance
  },

  async mounted() {
    var myCurrentDate = new Date();
    this.today = myCurrentDate.toISOString().replace("T", " ").substr(0, 19);
    await api
      .GetDriversRecentLocations(this.today)
      .then((res) => {
        // this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            if (element.locations.length != 0)
              this.markers.push({
                id: element.locations[0].id,
                icon: require("@/assets/images/icons/bus.svg"),
                info: element.locations[0].date_and_time,
                driverName: element.fname + " " + element.lname,
                driver_id: parseInt(element.id),
                position: {
                  lat: parseFloat(element.locations[0].lat),
                  lng: parseFloat(element.locations[0].lon),
                },
              });
          });
        }
      })
      .catch((error) => {
        this.error = error;
        // this.loading = false;
        this.errorDialog = true;
      });

    setInterval(() => {
      api
        .GetDriversRecentLocations(this.today)
        .then((res) => {
          if (res.data.responseCode === 1) {
            const temp = [];
            res.data.data.forEach((element) => {
              if (element.locations.length != 0)
                temp.push({
                  id: element.locations[0].id,
                  icon: require("@/assets/images/icons/bus.svg"),
                  info: element.locations[0].date_and_time,
                  driverName: element.fname + " " + element.lname,
                  driver_id: parseInt(element.id),
                  position: {
                    lat: parseFloat(element.locations[0].lat),
                    lng: parseFloat(element.locations[0].lon),
                  },
                });
            });

            this.markers = [...temp];
          }
        })
        .catch((error) => {
          this.error = error;
          // this.loading = false;
          this.errorDialog = true;
        });
    }, 10000);

    await api
      .gettrips()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            element.driver = {
              fullname: element.driver.fname + " " + element.driver.lname,
              id: element.driver.id,
            };

            if (element.started_date != null) {
              if (
                this.convertUTCDateToLocalDate(element.started_date) >
                this.convertUTCDateToLocalDate(element.start_date)
              ) {
                // Late
                element.status = 1;
              } else {
                element.status = 2; // Early
              }
            } else {
              element.status = 0; // Not yet
            }

            element.flag =
              element.flag == 0
                ? this.tenantType === "namat"
                  ? "Assigned"
                  : "Not Picked"
                : element.ended_date
                ? this.tenantType === "namat"
                  ? "Done"
                  : "Delivered"
                : this.tenantType === "namat"
                ? "In progress"
                : "Picked";

            element.ended_date =
              element.ended_date != null
                ? this.convertUTCDateToLocalDate(element.ended_date)
                : element.started_date != null
                ? "On route..."
                : "Waiting...";
            element.started_date =
              element.started_date == null
                ? "Waiting..."
                : this.convertUTCDateToLocalDate(element.started_date);

            element.start_date = this.convertUTCDateToLocalDate(
              element.start_date
            );
            element.created_date = this.convertUTCDateToLocalDate(
              element.created_date
            );

            this.tdata.push(element);
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      })
      .finally(() => {
        this.filtered_trips = this.tdata;
        this.generateDirections(this.filtered_trips);
      });
  },

  computed: {
    filteredItems() {
      return this.tdata.filter((i) => {
        return !this.filter || i.status === this.filter;
      });
    },
    column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
    },
  },

  watch: {
    filtered_trips(newFilteredTrips) {
      this.generateDirections(newFilteredTrips);
    },
  },

  methods: {
    showDialog(item) {
      this.selectedObject = item;
      this.driverInfo = true;
    },

    generateTask() {
      // Clear the textarea
      this.prompt = "";
      // Open the dialog
      this.generatedialog = true;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    getFiltered(e) {
      this.myCurrentData = e;
    },

    async focus(driverid) {
      this.selectedMarker = null;
      for (let index = 0; index < this.markers.length; index++) {
        var element = this.markers[index];
        if (element.driver_id === parseInt(driverid)) {
          this.selectedMarker = element;
        }
      }

      if (this.selectedMarker == null) {
        this.text = "Driver has not started the trip yet.";
        this.snackbar = true;
      }
    },

    focusRoute(trip_id, color) {
      let curr_route = null;

      for (let index = 0; index < this.routes.length; index++) {
        var element = this.routes[index];
        if (element.trip_id === parseInt(trip_id)) {
          curr_route = element;
        }
      }

      if (curr_route != null) {
        this.directions.drawTripLine(
          curr_route.origin,
          curr_route.destination,
          curr_route.waypoints,
          color
        );
      }
    },

    isInFilteredTrips(item) {
      return this.filtered_trips.includes(item);
    },

    toggleFilteredTrip(item) {
      if (this.isInFilteredTrips(item)) {
        this.filtered_trips = this.filtered_trips.filter(
          (trip) => trip !== item
        );
      } else {
        this.filtered_trips.push(item);
      }
    },

    openWindow(index, driverName, info) {
      this.infowindow = this.markers[index].position;
      this.driver_id = this.markers[index].driver_id;
      this.driverName = driverName;
      this.info = this.convertUTCDateToLocalDate(info);
      this.window_open = true;
      this.trip_window = false;
      this.origin_window = false;
    },

    openTripWindow(index, trip) {
      this.infowindow = trip.position;
      this.driver_id = trip.driver_id;
      this.driverName = trip.driver.fullname;
      this.info = trip.destination?.name ?? trip.destination_name;
      this.order = trip.order;
      console.log(this.trip_markers);
      this.trip_window = true;
      this.origin_window = false;
    },

    openOriginWindow(index) {
      this.infowindow = this.origin_markers[index].position;
      this.title = this.origin_markers[index].title;
      this.origin_window = true;
      this.trip_window = false;
    },

    generateDirections(trips) {
      if (!trips) return;

      if (trips.length === 0) {
        this.directions.reset();
      }

      this.trip_markers = [];
      this.origin_markers = [];

      trips.map((element) => {
        const waypoints = [];
        const is_origin =
          element.warehouse.lat !== null && element.warehouse.lat !== undefined;

        element.destinations.map(async (d, index) => {
          const lat = d.destination?.lat
            ? d.destination?.lat
            : d.destination_lat;
          const lon = d.destination?.lon
            ? d.destination?.lon
            : d.destination_lon;

          this.trip_markers.push({
            ...element,
            ...d,
            order: index + 1,
            trip_id: element.id,
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lon),
            },
          });

          if (
            (index === 0 && is_origin) ||
            (index !== 0 && index !== element.destinations.length - 1)
          ) {
            waypoints.push({
              location: {
                lat: parseFloat(lat),
                lng: parseFloat(lon),
              },
              stopover: true,
            });
          }

          if (is_origin) {
            this.origin_markers.push({
              trip_id: element.id,
              title: element.warehouse.title,
              position: {
                lat: parseFloat(element.warehouse.lat),
                lng: parseFloat(element.warehouse.lon),
              },
            });
          }

          if (index === element.destinations.length - 1) {
            const last_destination_lat = d.destination?.lat
              ? d.destination?.lat
              : d.destination_lat;
            const last_destination_lon = d.destination?.lon
              ? d.destination?.lon
              : d.destination_lon;

            const first_destination = element.destinations[0];
            const first_destination_lat = first_destination.destination?.lat
              ? first_destination.destination?.lat
              : first_destination.destination_lat;
            const first_destination_lon = first_destination.destination?.lon
              ? first_destination.destination?.lon
              : first_destination.destination_lon;

            const origin = {
              lat: is_origin ? element.warehouse.lat : first_destination_lat,
              lng: is_origin ? element.warehouse.lon : first_destination_lon,
            };

            const destination = {
              lat: last_destination_lat,
              lng: last_destination_lon,
            };

            await this.directions.drawTripLine(origin, destination, waypoints);
            this.routes.push({
              trip_id: element.id,
              driver_id: element.driver.id,
              origin,
              destination,
              waypoints,
            });
          }
        });
      });
    },

    closeWindow() {
      this.origin_window = false;
      this.trip_window = false;
      this.window_open = false;
    },

    // namat methods
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },

  getSiteIcon() {
    return require("@/assets/images/icons/bus.svg");
  },
};
