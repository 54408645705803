import api from "./api.js";

export default {
  data: () => ({
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    selectedItem: 1,
    snackbar: false,
    clicked: false,
    text: "",
    id: null,
    name: "",
    info: [],
    textProps: {
      "v-model": "msg",
    },
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    data: [],
    type: null,
    myCurrentData: [],
    infoDialog: false,
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      Name: "name",
    },
    headers: [
      {
        text: "Tenant Name",
        value: "name",
        align: "center",
      },
      { text: "Created Date", value: "created_date", align: "center" },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    newSetting: {
      key: "",
      value: "",
    },
  }),

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.data = [];
      await api
        .gettenants()
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = res.data.data.map((item) => {
              item.created_date = this.convertUTCDateToLocalDate(
                item.created_date
              );

              return item;
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });

      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        try {
          const res = await api.get_setting(item.id);
          item.settings = res.data.data;
        } catch (error) {
          console.log(`Error fetching setting for item with id ${item.id}`);
          console.log(error);
        }
      }

      console.log(this.data);
    },

    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },

    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },

    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },

    convertDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      var hr = date.getHours();
      var mn = date.getMinutes();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (hr < 10) {
        hr = "0" + hr;
      }
      if (mn < 10) {
        mn = "0" + mn;
      }
      var fulldate =
        year + "-" + month + "-" + dt + " " + hr + ":" + mn + ":00";
      return fulldate;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    convertToUTCDate() {
      const localDate = new Date();
      const utcDate = new Date(localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },

    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },

    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },

    agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = "Tenant Deleted Succesfully";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        })
        .finally(() => {
          this.loading = false;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },

    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.name = "";
        this.id = null;
      } else {
        this.selectedObject = item;

        this.id = item.id;
        this.name = item.name;
      }

      this.dialog = true;
    },

    navigateToInfo(item) {
      // Assuming you have Vue Router imported and set up
      this.$router.push(`/tenant/${item.id}/admins`);
    },

    async save() {
      this.clicked = true;

      if (!this.$refs.form.validate()) return false;
      this.loading = true;

      if (this.selectedObject.id != -1) {
        await api
          .save(this.id, this.name)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].name = this.name;

              this.text = "Tenant updated successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
          });

        this.selectedObject.settings.map(async (s) => {
          if (s.id) {
            await api.save_setting({
              tenant_id: this.selectedObject.id,
              id: s.id,
              key: s.key,
              value: s.value,
            });
            return;
          }

          await api.add_setting({
            tenant_id: this.selectedObject.id,
            key: s.key,
            value: s.value,
          });
        });
      } else {
        const date = this.convertToUTCDate();
        api
          .add(this.name, date)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;

            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                name: this.name,
                created_date: res.data.data.created_date,
              });

              this.text = "Tenant added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },

    addSetting() {
      // Add new setting to the selectedObject
      this.selectedObject.settings.push({
        key: this.newSetting.key,
        value: this.newSetting.value,
      });
      // Clear newSetting fields
      this.newSetting.key = "";
      this.newSetting.value = "";
    },

    removeSetting(index) {
      // Remove setting at index from selectedObject.settings
      this.selectedObject.settings.splice(index, 1);
      this.selectedObject = { ...this.selectedObject };
    },
  },

  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }

        case "lg": {
          return 4;
        }

        case "xl": {
          return 4;
        }
      }
    },
  },
};
