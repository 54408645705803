import api from "./api.js";
export default {
  data: () => ({
    loading: true,
    text: "",
    selectedDrivers: [],
    checkAll: false,
    sendingAll: false,
    sendAllDialog: false,
    picked: "1",
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    show2: false,
    Email: "",
    snackbarText: "",
    snackbar: false,
    myCurrentData: [],
    data: [],
    headers: [
      {
        text: "Select",
        align: "left",
        value: "check",
      },
      {
        text: "Full Name",
        align: "left",
        value: "fullname",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }
        case "sm": {
          return 4;
        }
        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  mounted() {
    this.getFinance();
    var myCurrentDate = new Date();
    var myFutureDate = new Date(myCurrentDate);
    myCurrentDate.setMonth(myCurrentDate.getMonth() - 1);
    myCurrentDate.setDate(1);
    myFutureDate.setMonth(myFutureDate.getMonth());
    myFutureDate.setDate(0);
    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);
    this.fetch();
  },
  watch: {
    checkAll: {
      handler() {
        this.data.forEach((element) => {
          element.checked = this.checkAll;
        });
      },
    },
  },
  methods: {
    askSendall() {
      this.sendAllDialog = true;
    },
    sendAll() {
      this.sendingAll = true;
      let temp = {
        fdate: this.fdate,
        tdate: this.tdate,
        email: this.Email,
      };

      if (!this.checkAll) {
        this.data.forEach((element) => {
          if (element.checked) {
            this.selectedDrivers.push(element.id);
          }
        });
        temp = { ...temp, selected_Drivers: this.selectedDrivers };
        this.selectedDrivers = [];
      }

      api.sendAllDriversSummary(temp).then((res) => {
        if (res.data.responseCode == 1) {
          this.sendAllDialog = false;
        } else if (res.data.responseCode == 2) {
          this.snackbarText = "No data for the selected drivers / date range.";
          this.snackbar = true;
        }
        this.sendingAll = false;
      });
    },
    getFinance() {
      api.getFinance().then((res) => {
        if (res.data.responseCode == 1) {
          this.Email = res.data.data;
        } else {
          this.Email = "";
        }
      });
    },
    fetch() {
      (this.data = []), (this.loading = true);
      api
        .getdrivers(this.fdate, this.tdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = res.data.data;
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
  },
};
