/* eslint-disable no-undef */
import { gmapApi } from "vue2-google-maps";

export default class Direction {
  constructor(refs) {
    this.$refs = refs;
    this.directionDisplay = null;
    this.drawTripLine = this.drawTripLine.bind(this);
  }

  get google() {
    return gmapApi;
  }

  async reset() {
    if (this.directionDisplay) {
      await this.directionDisplay.setMap(null);
      await this.directionDisplay.setDirections({ routes: [] });
    }
  }

  drawTripLine = async (origin, destination, waypoints, color = "gray") => {
    let google = await this.google();

    if (!google) return;

    let directionService = await new google.maps.DirectionsService();

    let travel = {
      origin,
      destination,
      travelMode: "DRIVING",
      optimizeWaypoints: true,
    };

    if (waypoints.length > 0) {
      travel.waypoints = waypoints;
    }

    // Clear previous directions if any
    if (this.directionDisplay) {
      await this.directionDisplay.setMap(null);
      await this.directionDisplay.setDirections({ routes: [] });
    }

    await directionService.route(travel, async (res, status) => {
      if (status === "OK") {
        let map = this.$refs.mapRef;

        if (this.directionDisplay) {
          await this.directionDisplay.setMap(null);
          await this.directionDisplay.setDirections({ routes: [] });
        }

        this.directionDisplay = await new google.maps.DirectionsRenderer({
          map: map.$mapObject,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 5,
          },
        });

        var route = res.routes[0];
        for (var i = 0; i < route.legs.length; i++) {
          new google.maps.Marker({
            position: route.legs[i].start_location,
            label: "" + (i + 1),
            // map: map,
          });
        }

        this.directionDisplay.setDirections(res);
      }
    });
  };
}
