import api from "./api.js";
export default {
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    reference: null,
    clicked: false,
    snackbar: false,
    text: "",
    search: "",
    id: null,
    json_fields: {
      Id: "id",
      Status: "statusText",
      Reference: "reference",
      "Assigned To": "driverFullName",
    },

    NormalRules: [(v) => !!v || "Field is required"],
    myCurrentData: [],
    ddata: [],
    headers: [
      {
        text:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "Name"
            : "Reference",
        align: "center",
        value: "reference",
      },
      {
        text: "Status",
        align: "center",
        value:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "statusText"
            : "drivers[0]",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    // api
    //   .getbuses()
    //   .then((res) => {
    //     this.loading = false;
    //     if (res.data.responseCode === 1) {
    //       res.data.data.forEach((element) => {
    //         switch (element.taken) {
    //           case "1":
    //             element.statusText = "Assigned";
    //             element.driverFullName =
    //               element.drivers[0].fname + " " + element.drivers[0].lname;
    //             break;
    //           case "0":
    //             element.statusText = "No Assigned";
    //             element.driverFullName = "N/A";
    //             break;

    //           default:
    //             break;
    //         }
    //         this.ddata.push(element);
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     this.error = error;
    //     this.loading = false;
    //     this.errorDialog = true;
    //   });
    this.loading = false;
    const resources = [
      { id: 1, drivers: [], reference: "Runway 1", statusText: "Available" },
      {
        id: 2,
        drivers: [],
        reference: "Runway 2",
        statusText: "Full-capacity",
      },
      { id: 3, drivers: [], reference: "Gate A1", statusText: "Half-capacity" },
      { id: 4, drivers: [], reference: "Gate B2", statusText: "Available" },
      {
        id: 5,
        drivers: [],
        reference: "Fuel Truck 3",
        statusText: "Full-capacity",
      },
      {
        id: 6,
        drivers: [],
        reference: "Baggage Carousel 1",
        statusText: "Half-capacity",
      },
      { id: 7, drivers: [], reference: "Taxiway C", statusText: "Available" },
      {
        id: 8,
        drivers: [],
        reference: "Control Tower",
        statusText: "Available",
      },
      {
        id: 9,
        drivers: [],
        reference: "Fire Station",
        statusText: "Half-capacity",
      },
      {
        id: 11,
        drivers: [],
        reference: "Hangar 4",
        statusText: "Full-capacity",
      },
      {
        id: 12,
        drivers: [],
        reference: "Maintenance Crew 1",
        statusText: "Available",
      },
      {
        id: 13,
        drivers: [],
        reference: "Maintenance Crew 2",
        statusText: "Half-capacity",
      },
      {
        id: 14,
        drivers: [],
        reference: "Security Team A",
        statusText: "Full-capacity",
      },
    ];

    this.ddata = resources;
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.reference = null;
        this.id = null;
      } else {
        this.selectedObject = item;
        this.reference = item.reference;

        this.id = item.id;
      }
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.ddata.indexOf(this.selectedObject);
            this.$delete(this.ddata, index);
            this.text = "Vehicle deleted succesfully.";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;

          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      this.loading = true;
      if (this.selectedObject.id != -1) {
        api
          .save(this.id, this.reference)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.ddata.indexOf(this.selectedObject);
              this.ddata[index].reference = this.reference;

              this.text = "Vehicle updated successfully";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      } else {
        api
          .add(this.reference)
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.ddata.push({
                id: res.data.data.id,
                reference: this.reference,
                statusText: "No Assigned",
                driverFullName: "N/A",
                drivers: [],
              });
              this.text = "Vehicle added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
  },
};
